<template>
  <div id="app">
    <p>WELCOME TO MY WEBSITE</p>
    <img
      alt="Vue logo"
      src="./assets/23USC-6748601113-7-1008550741-1.jpg"
      style="width: 25%; height: auto; object-fit: cover; margin: 0; padding: 0"
    />

    <HelloWorld
      msg="My Name is Simardeep Singh"
      style="max-width: 50vw; margin: 0 auto"
    />
  </div>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "App",
  components: {
    HelloWorld,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
