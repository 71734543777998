<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p style="max-width: 50vw; margin: 0 auto">
      I am curently working on Coding my own website with all my projects,
      please check back later or contact me to see when it will be done! In the
      meantime, check out my linked in!
      <a
        href="https://www.linkedin.com/in/simardeep-singh-"
        target="_blank"
        rel="noopener"
        >Linked In</a
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
